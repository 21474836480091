import React from "react";
import { graphql } from "gatsby";
import CTASection from "../components/misc/cta-section";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/seo";
import SectionSeparator from "../components/layout/section-separator";
import CustomerCaseStudyBoxes from "../components/customer/customer-case-study-boxes";
import CustomerTestimonial from "../components/customer/customer-testimonial";
import MarkdownContainer from "../components/misc/markdown-container";
import Link from "../components/misc/link";

const CustomerCaseStudy = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const customer = data.contentfulCustomer;
    const caseStudy = customer.case_study;
    const bodyClasses = `case-study ${pageContext.type + "s"} ${caseStudy.slug}`;

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            pageHeading={customer.company_name}
            pageSubHeading={caseStudy.page_heading}
        >
            <Seo
                title={caseStudy.page_title}
                description={caseStudy.page_description}
                slug={"/customers/" + caseStudy.slug}
                bodyClass={bodyClasses}
            />
            <section className="st-section--greylight details-heading">
                <div className="content">
                    {caseStudy.headingBlurb ? (
                        <>
                            <div className="heading-grid heading-grid-3">
                                <h4>Industry</h4>
                                {caseStudy.caseStudyIndustry.industryName}
                            </div>
                            <div className="heading-grid heading-grid-3">
                                <h4>Company size</h4>
                                {caseStudy.companySize.companySize}
                            </div>
                            <div className="heading-grid heading-grid-3">
                                <h4>{caseStudy.headingBlurbTitle}</h4>
                                {caseStudy.headingBlurb.headingBlurb}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="heading-grid heading-grid-2">
                                <h4>Industry</h4>
                                {caseStudy.caseStudyIndustry.industryName}
                            </div>
                            <div className="heading-grid heading-grid-2">
                                <h4>Company size</h4>
                                {caseStudy.companySize.companySize}
                            </div>
                        </>
                    )}
                </div>
            </section>
            {caseStudy.section1Content && (
                <section className="st-section--narrow">
                    {caseStudy.customerIntroduction && customer.logo.file.url && (
                        <>
                            <div className="st-client-casestudy-logo-container">
                                <img
                                    src={customer.logo.file.url}
                                    alt="Customer Logo"
                                    className="st-client-casestudy-logo"
                                />
                            </div>
                            <MarkdownContainer
                                containerClasses={`content`}
                                content={caseStudy.customerIntroduction.customerIntroduction}
                            />
                        </>
                    )}
                    {caseStudy.section1Heading && (
                        <h2 className="st-heading--2 section-heading">{caseStudy.section1Heading}</h2>
                    )}
                    {caseStudy.customerIntroduction === null && customer.logo.file.url && (
                        <Link to={customer.url} target="new">
                            <div className="st-client-casestudy-logo-container">
                                <img
                                    src={customer.logo.file.url}
                                    alt="Customer Logo"
                                    className="st-client-casestudy-logo"
                                />
                            </div>
                        </Link>
                    )}
                    <MarkdownContainer
                        containerClasses={`content`}
                        content={caseStudy.section1Content.section1Content}
                    />
                </section>
            )}

            {caseStudy.section2Content && (
                <section className="st-section--narrow">
                    {caseStudy.section2Heading && (
                        <h2 className="st-heading--2 section-heading">{caseStudy.section2Heading}</h2>
                    )}
                    <MarkdownContainer
                        containerClasses={`content`}
                        content={caseStudy.section2Content.section2Content}
                    />
                </section>
            )}

            {caseStudy.pullQuote1 && (
                <>
                    <CustomerTestimonial
                        quote={caseStudy.pullQuote1.pullQuote1}
                        logoUrl={customer.one_color_logo.file.url}
                        logoAltText={caseStudy.page_heading}
                    />
                    <SectionSeparator />
                </>
            )}

            {caseStudy.section3Content && (
                <section className="st-section--narrow">
                    {caseStudy.section3Heading && (
                        <h2 className="st-heading--2 section-heading">{caseStudy.section3Heading}</h2>
                    )}
                    <MarkdownContainer
                        containerClasses={`content`}
                        content={caseStudy.section3Content.section3Content}
                    />
                </section>
            )}

            {caseStudy.pullQuote2 && (
                <>
                    <SectionSeparator />
                    <CustomerTestimonial quote={caseStudy.pullQuote2.pullQuote2} />
                    <SectionSeparator />
                </>
            )}
            <CTASection
                heading={`Join ${customer.company_name} and start replicating data`}
                subheading={`Select your integrations, choose your warehouse, and enjoy Stitch free for 14 days.`}
                dualCTA={true}
                containerClasses={`st-section--lowlight`}
            />
            <CustomerCaseStudyBoxes limit={3} heading={`See other success stories`} />
        </Layout>
    );
};

export default CustomerCaseStudy;

export const query = graphql`
    query($slug: String!) {
        contentfulCustomer(slug: { eq: $slug }) {
            id
            url
            company_name
            logo {
                file {
                    url
                }
            }
            one_color_logo {
                file {
                    url
                }
            }
            case_study {
                slug
                page_heading
                page_description
                page_title
                customerIntroduction {
                    customerIntroduction
                }
                section1Heading
                section1Content {
                    section1Content
                }
                section2Heading
                section2Content {
                    section2Content
                }
                section3Heading
                section3Content {
                    section3Content
                }
                pullQuote1 {
                    pullQuote1
                }
                pullQuote2 {
                    pullQuote2
                }
                caseStudyIndustry {
                    industryName
                }
                companySize {
                    companySize
                }
                customerIntegrations {
                    customerIntegrations
                }
                headingBlurbTitle
                headingBlurb {
                    headingBlurb
                }
            }
        }
    }
`;
